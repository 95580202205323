import React, { useEffect, useRef } from "react"
import gsap from "gsap"

const Section = ({ children, theme }) => {
  const section = useRef(null)

  useEffect(() => {
    let inView = false

    const computedStyle = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--site-header-height")

    const siteHeaderHeight = Number(computedStyle.replace("rem", "")) * 16

    const animate = time => {
      const rect = section.current.getBoundingClientRect()

      if (
        rect.top - siteHeaderHeight < 0 &&
        rect.bottom - siteHeaderHeight > 0
      ) {
        if (!inView) {
          document.documentElement.style.setProperty("--header-bg", theme[0])
          document.documentElement.style.setProperty("--header-text", theme[1])
        }
        inView = true
      } else {
        inView = false
      }
    }

    gsap.ticker.add(animate)

    return () => gsap.ticker.remove(animate)
  }, [theme])

  return <div ref={section}>{children}</div>
}

export default Section
