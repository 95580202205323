import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { Fade } from "react-awesome-reveal"

import "./Footer.css"
// import FooterCTA from "./FooterCTA"

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allCraftSocialLinksNode {
        nodes {
          title
          url
        }
      }
      craftGeneralGlobalSet {
        emailAddress
        address
        footerLinkPrimary {
          slug
          title
        }
        footerLinkSecondary {
          slug
          title
        }
        headerLink {
          text
          element {
            ... on Craft_register_register_Entry {
              slug
            }
            ... on Craft_pages_pages_Entry {
              slug
            }
          }
        }
      }
    }
  `)
  const {
    address,
    emailAddress,
    footerLinkPrimary,
    footerLinkSecondary,
  } = data.craftGeneralGlobalSet

  const socialLinks = data.allCraftSocialLinksNode.nodes

  return (
    <div className="text-light-sand bg-teal font-tomato relative z-10 border-t border-sand">
      <div className="grid grid-cols-8 gap-x-gs gap-y-16 lg:gap-y-40 px-ogs pt-20 text-2xs lg:text-sm">
        <div className="col-span-8 lg:col-span-4 lg:self-end">
          <Fade triggerOnce duration={750} fraction={0.25} delay={250}>
            <h3 className="text-7xlb lg:text-8xl font-fletcha tracking-tightest leading-none">
              Contact us
            </h3>
          </Fade>
        </div>

        {/* <div className="row-start-4 lg:row-start-1 col-span-8 lg:col-start-5 lg:col-span-4 pt-24 lg:pt-0 ">
          <Fade triggerOnce duration={750} fraction={0.25} delay={250}>
            <FooterCTA headerLink={headerLink} />
          </Fade>
        </div> */}

        {socialLinks.length && (
          <Fade
            triggerOnce
            duration={500}
            fraction={0.25}
            className="col-start-6 col-end-9 md:col-start-1 md:col-end-3 leading-6"
          >
            <ul>
              {socialLinks.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      className="Link"
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.title}
                    </a>
                  </li>
                )
              })}
            </ul>
          </Fade>
        )}
        <div className="w-full row-start-2 col-start-1 col-end-6 lg:row-start-2 lg:col-start-3 lg:col-end-5 leading-6">
          <Fade triggerOnce duration={750} fraction={0.25}>
            {address &&
              address.split("\n").map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                )
              })}
          </Fade>
        </div>

        {emailAddress && (
          <div className="col-span-4 row-start-3 lg:row-start-2 lg:col-start-5 tracking-wide">
            <Fade triggerOnce duration={750} fraction={0.25}>
              <a className="Link" href={`mailto:${emailAddress}`}>
                {emailAddress}
              </a>
            </Fade>
          </div>
        )}
      </div>
      <Fade triggerOnce duration={750} fraction={0.25}>
        <div className="py-8 lg:pt-16 text-2xs tracking-wide grid grid-cols-8 gap-gs px-ogs text-teal-support">
          <div className="w-full text-center lg:text-left col-start-1 col-end-9 lg:col-start-1 lg:col-end-5">
            &copy; {new Date().getFullYear()} {siteTitle}{" "}
            <span className="hidden lg:inline">All rights reserved.</span> |{" "}
            {footerLinkPrimary[0] && (
              <Link
                className="Footer-links"
                to={`/${footerLinkPrimary[0].slug}`}
              >
                {footerLinkPrimary[0].title}
              </Link>
            )}
            {footerLinkSecondary[0] && (
              <span className="lg:hidden">
                {" "}
                |{" "}
                <Link
                  className="Footer-links"
                  to={`/${footerLinkSecondary[0].slug}`}
                >
                  {footerLinkSecondary[0].title}
                </Link>
              </span>
            )}
          </div>
          {footerLinkSecondary[0] && (
            <div className="w-full hidden lg:block lg:col-start-5 lg:col-end-9">
              <Link
                className="Footer-links"
                to={`/${footerLinkSecondary[0].slug}`}
              >
                {footerLinkSecondary[0].title}
              </Link>
            </div>
          )}
        </div>
      </Fade>
    </div>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
